import React from "react"
import * as styles from "../sections/copy.module.css";
import { Button } from "react-bootstrap"
import Container from "../components/container";
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Heading from "../components/heading"
import { FaWhatsapp } from "react-icons/fa"
import Testimonials from "../components/Testimonials"

const PlanejamentoCurricularPage = () => (
  <Layout>
    <SEO title="Planejamento Curricular" />
    <Container>
      <br />
      <Heading center className="cl-identity">Planejamento Curricular</Heading>
      <Heading secondary center>
        Um planejamento personalizado para o seu filho, com direito a dicas de materiais e um currículo anual.
      </Heading>

      <Container className={styles.copyContainer}>
        <p className={styles.text}>
          A consultoria <i>"Planejamento Curricular”</i> é destinada a pais ou responsáveis interessados em
          homeschooling que desejam um planejamento curricular personalizado. Esse plano oferece um encontro inicial via
          chamada de vídeo de <b>45 minutos</b> para conhecer a família e seus objetivos, seguido por um segundo
          encontro de <b>1 hora</b> para apresentar o currículo personalizado e indicar materiais didáticos.
          <br /><br />
          <b>Objetivo:</b> O objetivo principal é fornecer orientações especializadas e criar um currículo personalizado
          para atender às necessidades educacionais específicas da família.
          <br /><br />
          <b>Encontro inicial via chamada de vídeo:</b> No primeiro encontro, com duração de 45 minutos, será realizada
          uma conversa detalhada para conhecer a família, suas prioridades educacionais, os interesses das crianças e
          suas metas de aprendizado. Essa etapa é fundamental para compreender as necessidades individuais e
          personalizar o plano curricular.
          <br /><br />
          <b>Análise e planejamento curricular:</b> Com base nas informações coletadas no encontro inicial, eu irei
          desenvolver um plano curricular personalizado que se alinhe com as preferências, metas e estilo de
          aprendizagem da família. Serão considerados os diferentes assuntos acadêmicos, habilidades e níveis de ensino
          apropriados para cada criança.
          <br /><br />
          <b>Segundo encontro via chamada de vídeo:</b> No segundo encontro, com duração de 1 hora, irei apresentar o
          currículo personalizado e fornecer orientações sobre como implementá-lo. Serão sugeridos materiais didáticos,
          recursos educacionais e estratégias de ensino adequadas às necessidades da família.
          <br /><br />
          <b>Limitações:</b> É importante destacar que a consultoria <i>"Planejamento Curricular”</i> não oferece
          assessoria jurídica específica, abrangendo apenas aspectos educacionais. Os aspectos legais relacionados ao
          homeschooling devem ser consultados com profissionais especializados.
          <br /><br />
          Esse plano de consultoria visa fornecer suporte completo para pais ou responsáveis interessados em
          homeschooling, oferecendo um planejamento curricular personalizado e orientações específicas. O foco é criar
          um ambiente educacional adaptado às necessidades e objetivos individuais, proporcionando uma experiência de
          aprendizado significativa e personalizada para as crianças.
          <br /><br />
          <b>Obs:</b> o valor do plano <i>"Planejamento Curricular”</i> <u>abrange apenas um filho</u>. <b>Para cada
          filho adicional</b> que a família queira incluir, será cobrado o valor <b>adicional de R$ 78,90</b> <u>por
          filho</u>.
        </p>

        <div>
          <b>Investimento</b>
          <br />
          <h2 className="cl-identity font-weight-bold">R$ 187,90 no pix</h2>
          <br />
          <b>Ou em até 12x de</b>
          <p className="cl-identity">R$ 19,04 no cartão</p>
        </div>
      </Container>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.learnMoreButton}
          href="https://api.whatsapp.com/send?phone=5548996507547&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20hor%C3%A1rio%20para%20Planejamento%20Curricular."
          target="_blank"
          rel="noopener noreferrer"
        >
          Agendar um horário
          <FaWhatsapp size={24} className="mx-2" />
        </Button>
      </div>

      <br />
      <br />
      <br />
      <Testimonials />

    </Container>
  </Layout>
)

export default PlanejamentoCurricularPage
